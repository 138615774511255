<ng-container *transloco="let t">
  @for (facebookAccount of facebookAccounts; track facebookAccount; let last = $last) {
    <mat-list>
      <mat-list-item>
        <sb-facebook-account [facebookAccount]="facebookAccount">
          <ng-template #rightSideTemplate>
            <div class="mt-5 flex flex-wrap items-center justify-center md:mt-0">
              <!-- Make primary -->
              @if (enableMakePrimary && !facebookAccount.is_primary) {
                <sb-button (sbClick)="onMakePrimaryFacebookAccount(facebookAccount)" icon="outlined:facebook">
                  {{ t("actions.UseAsLoginAccount") }}
                </sb-button>
              }
              @if (enableMakePrimary && facebookAccount.is_primary) {
                <sb-text class="px-6" variant="labelLarge" muted truncate noMargin>
                  <div class="flex items-center">
                    <mat-icon svgIcon="outlined:facebook" [ngStyle]="{ width: '20px', heigth: '20px' }" class="mr-2" />
                    {{ t("info.LoginAccount") }}
                  </div>
                </sb-text>
              }
              <!-- Refresh token -->
              <sb-button (sbClick)="onRefreshFacebookAccount(facebookAccount)" variant="text" icon="outlined:cached">
                {{ t("actions.RefreshAuth") }}
              </sb-button>
              <!-- Delete -->
              @if (enableDelete) {
                <sb-button
                  (sbClick)="onDeleteFacebookAccount(facebookAccount)"
                  variant="text"
                  themeColor="warn"
                  icon="outlined:delete"
                >
                  {{ t("actions.Delete") }}
                </sb-button>
              }
            </div>
          </ng-template>
        </sb-facebook-account>
      </mat-list-item>
    </mat-list>
  }
</ng-container>
