<ng-container *transloco="let t">
  @for (linkedinAccount of linkedinAccounts; track linkedinAccount; let last = $last) {
    <mat-list>
      <mat-list-item>
        <sb-linkedin-account [linkedinAccount]="linkedinAccount">
          <ng-template #rightSideTemplate>
            <div class="mt-5 flex flex-wrap items-center justify-center md:mt-0">
              <!-- Make primary -->
              @if (enableMakePrimary && !linkedinAccount.is_primary) {
                <sb-button
                  variant="text"
                  (sbClick)="onMakePrimaryLinkedinAccount(linkedinAccount)"
                  icon="outlined:verified"
                >
                  {{ t("actions.MakePrimaryAccount") }}
                </sb-button>
              }
              @if (enableMakePrimary && linkedinAccount.is_primary) {
                <sb-text class="px-6" variant="labelLarge" muted truncate noMargin>
                  <div class="flex items-center">
                    <mat-icon svgIcon="outlined:verified" [ngStyle]="{ width: '20px', heigth: '20px' }" class="mr-2" />
                    {{ t("info.PrimaryAccount") }}
                  </div>
                </sb-text>
              }
              <!-- Refresh token -->
              <sb-button (sbClick)="onRefreshLinkedinAccount(linkedinAccount)" variant="text" icon="outlined:cached">
                {{ t("actions.RefreshAuth") }}
              </sb-button>
              <!-- Delete -->
              @if (enableDelete) {
                <sb-button
                  (sbClick)="onDeleteLinkedinAccount(linkedinAccount)"
                  variant="text"
                  themeColor="warn"
                  icon="outlined:delete"
                >
                  {{ t("actions.Delete") }}
                </sb-button>
              }
            </div>
          </ng-template>
        </sb-linkedin-account>
      </mat-list-item>
    </mat-list>
  }
</ng-container>
