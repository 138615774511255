import { NgStyle } from '@angular/common'
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core'
import { MatIconModule } from '@angular/material/icon'
import { MatListModule } from '@angular/material/list'
import { TranslocoModule } from '@ngneat/transloco'

import { LinkedinAccount } from '~features/integrations/models/linkedin-account.model'

import { ButtonComponent } from '../../ui/button/button.component'
import { TextComponent } from '../../ui/text/text.component'
import { LinkedinAccountComponent } from '../linkedin-account/linkedin-account.component'

@Component({
  selector: 'app-linkedin-account-list',
  templateUrl: './linkedin-account-list.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    TranslocoModule,
    MatListModule,
    LinkedinAccountComponent,
    ButtonComponent,
    TextComponent,
    MatIconModule,
    NgStyle,
  ],
})
export class LinkedinAccountListComponent {
  @Output() deleteLinkedinAccount: EventEmitter<LinkedinAccount> = new EventEmitter<LinkedinAccount>()
  @Input() enableDelete = false
  @Input() enableMakePrimary = false
  @Input() linkedinAccounts: LinkedinAccount[]
  @Output() makePrimaryLinkedinAccount: EventEmitter<LinkedinAccount> = new EventEmitter<LinkedinAccount>()
  @Output() refreshLinkedinAccount: EventEmitter<LinkedinAccount> = new EventEmitter<LinkedinAccount>()

  onDeleteLinkedinAccount(linkedinAccount: LinkedinAccount): void {
    this.deleteLinkedinAccount.emit(linkedinAccount)
  }

  onMakePrimaryLinkedinAccount(linkedinAccount: LinkedinAccount): void {
    this.makePrimaryLinkedinAccount.emit(linkedinAccount)
  }

  onRefreshLinkedinAccount(linkedinAccount: LinkedinAccount): void {
    this.refreshLinkedinAccount.emit(linkedinAccount)
  }
}
