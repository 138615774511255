import { NgStyle } from '@angular/common'
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core'
import { MatIconModule } from '@angular/material/icon'
import { MatListModule } from '@angular/material/list'
import { TranslocoModule } from '@ngneat/transloco'

import { FacebookAccount } from '~features/integrations/models/facebook-account.model'

import { ButtonComponent } from '../../ui/button/button.component'
import { TextComponent } from '../../ui/text/text.component'
import { FacebookAccountComponent } from '../facebook-account/facebook-account.component'

@Component({
  selector: 'app-facebook-account-list',
  templateUrl: './facebook-account-list.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    TranslocoModule,
    MatListModule,
    FacebookAccountComponent,
    ButtonComponent,
    TextComponent,
    MatIconModule,
    NgStyle,
  ],
})
export class FacebookAccountListComponent {
  @Output() deleteFacebookAccount: EventEmitter<FacebookAccount> = new EventEmitter<FacebookAccount>()
  @Input() enableDelete = false
  @Input() enableMakePrimary = false
  @Input() facebookAccounts: FacebookAccount[]
  @Output() makePrimaryFacebookAccount: EventEmitter<FacebookAccount> = new EventEmitter<FacebookAccount>()
  @Output() refreshFacebookAccount: EventEmitter<FacebookAccount> = new EventEmitter<FacebookAccount>()

  onDeleteFacebookAccount(facebookAccount: FacebookAccount): void {
    this.deleteFacebookAccount.emit(facebookAccount)
  }

  onMakePrimaryFacebookAccount(facebookAccount: FacebookAccount): void {
    this.makePrimaryFacebookAccount.emit(facebookAccount)
  }

  onRefreshFacebookAccount(facebookAccount: FacebookAccount): void {
    this.refreshFacebookAccount.emit(facebookAccount)
  }
}
